<template>
	<div id="ag-grid-demo">
		<vx-card id="grid-loader" class="vs-con-loading__container">
			<ag-grid-vue
				:gridOptions="gridOptions"
				class="ag-theme-material w-100 mb-4 ag-grid-table"
				:columnDefs="columnDefs"
				:defaultColDef="defaultColDef"
				:rowData="tableData"
				rowSelection="multiple"
				:enableRangeSelection="true"
				:suppressCopyRowsToClipboard="true"
				colResizeDefault="shift"
				:suppressDragLeaveHidesColumns="true"
				:animateRows="false"
				:floatingFilter="true"
				:pagination="true"
				:paginationPageSize="paginationPageSize"
				:suppressPaginationPanel="true"
				:suppressRowClickSelection="true"
				:rowClassRules="rowClassRules"
				:statusBar="statusBar"
				:sideBar="sideBar"
				:rowHeight="38"
				:getContextMenuItems="getContextMenuItems"
				@filter-changed="onFilterChanged"
				@column-resized="onColumnResized">
			</ag-grid-vue>
			<div class="vx-row">
				<div class="vx-col w-full">
					<vs-pagination
					:total="totalPages"
					:max="maxPageNumbers"
					v-model="currentPage" />
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	export default {
		components: {
			AgGridVue,
		},
		data() {
			return {
				searchQuery: '',
				gridOptions: {},
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filter: false,
					filterParams: {
						suppressAndOrCondition: true,
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				columnDefs: [
				{
					headerName: 'Rule Name',
					minWidth: 350,
					filter: false,
					pinned: 'left',
					valueGetter: (data) => {
						if(data.node.data.short_name) {
							return `${data.node.data.short_name} | ${data.node.data.tld} | ${data.node.data.source}`
						}
						else if(data.node.data.registrar) {
							return `${data.node.data.registrar} | ${data.node.data.tld} | ${data.node.data.source}`
						}
						else {
							return ''
						}
					},
				},
				{
					headerName: 'Active',
					field: 'active',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: [1,0],
						suppressMiniFilter: true,
						cellRenderer: (data) => {
							if (parseInt(data.value) === 0) {
								return 'no';
							}
							else if (parseInt(data.value) === 1) {
								return 'yes';
							}
							else {
								return data.value;
							}
						}
					},
					cellRenderer: (data) => {
						if(data.value) {
							return `<div class="badge badge--success inline">yes</div>`
						}
						else {
							return `<div class="badge badge--danger inline">no</div>`
						}
					},
					minWidth: 150,
				},
				{
					headerName: 'Short Name',
					field: 'short_name',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Registrar ID',
					field: 'registrar_id',
					filter: "agTextColumnFilter",
					minWidth: 150,
				},
				{
					headerName: 'Registrar',
					field: 'registrar',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'TLD',
					field: 'tld',
					filter: "agSetColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep',
						values: ['site','website','store','online','tech','press','host','fun','space','uno','pw','in.net'],
						suppressMiniFilter: true,
					},
					minWidth: 100,
					width: 100,
				},
				{
					headerName: 'Source ID',
					field: 'source_id',
					filter: "agTextColumnFilter",
					minWidth: 200,
					hide: true,
				},
				{
					headerName: 'Source',
					field: 'source',
					filter: "agTextColumnFilter",
					minWidth: 275,
				},
				{
					headerName: 'Created At',
					field: 'created_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					},
					sort: 'desc',
				},
				{
					headerName: 'Updated At',
					field: 'updated_at',
					filter: 'agMultiColumnFilter',
					filterParams: {
						filters: [
							{
								filter: 'agTextColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									newRowsAction: 'keep',
								}
							},
							{
								filter: 'agDateColumnFilter',
								filterParams: {
									suppressAndOrCondition: true,
									defaultOption: 'inRange',
									newRowsAction: 'keep',
									comparator: function(filterLocalDateAtMidnight, cellValue) {
										if (cellValue === null) return -1;

										let cellDate = new Date(cellValue);
										
										let cellDateString = new Date(cellDate.toDateString());
										let filterLocalDateAtMidnightString = new Date(filterLocalDateAtMidnight.toDateString());
										
										if (cellDateString.getTime() === filterLocalDateAtMidnightString.getTime()) {
											return 0;
										}
										if (cellDate < filterLocalDateAtMidnight) {
											return -1;
										}
										if (cellDate > filterLocalDateAtMidnight) {
											return 1;
										}
									},
								}
							},
						],
					},
					minWidth: 235,
					cellRenderer: (data) => {
						if(data.value) {
							return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
						}
						else {
							return ''
						}
					}
				},
				],
				tableData: null,
				deactivateNode: {
					id: [],
					active: 0
				}
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 500
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
		},
		methods: {
			showDivLoading(){
				this.$vs.loading({
					container: '#grid-loader',
					scale: 0.45
				});
			},
			hideDivLoading(){
				this.$vs.loading.close("#grid-loader > .con-vs-loading");
			},
			onFilterChanged() {
				// Set Listing Filtered Count
				this.$store.dispatch("setListingFilteredCount", this.gridApi.getDisplayedRowCount());
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			toggleColumnPinned(val) {
				if(val <= 576) {
					this.maxPageNumbers = 4;
					this.gridOptions.columnApi.setColumnPinned('domain', null);
				}
				else {
					this.gridOptions.columnApi.setColumnPinned('domain', 'left')
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			updateSearchQuery(val) {
				this.gridApi.setQuickFilter(val);
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			openConfirm(type) {
				if(type == 'deactivate') {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Deactivate the selected rule(s)?',
						accept: this.acceptDeactivateAlert
					});
				}
				else {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Activate the selected rule(s)?',
						accept: this.acceptActivateAlert
					});
				}
			},
			acceptDeactivateAlert() {
				let self = this;
				// Show loader
				this.showDivLoading();
				// Post Axios Call
				this.$axiosSecure.post('/rts-rule-edit', {
					...self.deactivateNode,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					self.$vs.notify({
						color: 'success',
						title: 'Rule deactivate success',
						text: 'The selected rule(s) has been deactivated'
					});
					
					// Table Data AXIOS Call
					this.populateTableData('loader');
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			acceptActivateAlert() {
				let self = this;
				// Show loader
				this.showDivLoading();
				// Post Axios Call
				this.$axiosSecure.post('/rts-rule-edit', {
					...self.deactivateNode,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then(() => {
					// Confimation notification
					self.$vs.notify({
						color: 'success',
						title: 'Rule activate success',
						text: 'The selected rule(s) has been activated'
					});
					
					// Table Data AXIOS Call
					this.populateTableData('loader');
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			populateTableData(loader) {
				let self = this;

				this.$axiosSecure.get('/rts-rules-json')
				.then((response) => {
					this.tableData = response.data;

					// Set Listing Count
					this.$store.dispatch("setListingTotalCount", response.data.length);
					// Set Listing Filtered Count
					this.$store.dispatch("setListingFilteredCount", response.data.length);
				
					if(loader == 'loader') {
						// Hide loader
						this.hideDivLoading();
					}
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			getContextMenuItems(params) {
				let result,
					self = this,
					rangeSelection = this.gridApi.getCellRanges(),
					singleCell = false;

				// Reset Deactivate Node ID
				this.deactivateNode['id'] = [];

				if(rangeSelection) {
					// Get selected cells & iterate through them to find rules
					rangeSelection.forEach(item => {
						let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
							end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
							isRightClicked = false;
						
						for (let i = start; i <= end; i++) {
							if(self.gridApi.getDisplayedRowAtIndex(i)['data']['id'] == params['node']['data']['id']) {
								isRightClicked = true;
								singleCell = true;
							}
							self.deactivateNode['id'].push(self.gridApi.getDisplayedRowAtIndex(i)['data']['id']);
						}
						
						if(!isRightClicked) {
							self.deactivateNode['id'].push(params['node']['data']['id']);
						}
					});

					// If only one cell is selected
					if(rangeSelection.length == 1 && singleCell && rangeSelection[0]['start']['rowIndex'] == rangeSelection[0]['end']['rowIndex']) {
						if(params['node'] && params['node']['data'] && params['node']['data']['active'] && params['node']['data']['active'] == 1) {
							result = [
								'copy',
								'copyWithHeaders',
								'paste',
								'separator',
								'export',
								'separator',
								{
									name: "Deactivate",
									action: () => {
										self.deactivateNode['id'].push(params['node']['data']['id']);
										self.deactivateNode['active'] = 0;
										this.openConfirm('deactivate');
									},
								},
							];
						}
						else {
							result = [
								'copy',
								'copyWithHeaders',
								'paste',
								'separator',
								'export',
								'separator',
								{
									name: "Activate",
									action: () => {
										self.deactivateNode['id'].push(params['node']['data']['id']);
										self.deactivateNode['active'] = 1;
										this.openConfirm('activate');
									},
								},
							];
						}
					}
					else {
						result = [
							'copy',
							'copyWithHeaders',
							'paste',
							'separator',
							'export',
							'separator',
							{
								name: "Bulk Activate",
								action: () => {
									self.deactivateNode['active'] = 1;
									this.openConfirm('activate');
								},
							},
							{
								name: "Bulk Deactivate",
								action: () => {
									self.deactivateNode['active'] = 0;
									this.openConfirm('deactivate');
								},
							},
						];
					}
				}
				else {
					if(params['node'] && params['node']['data'] && params['node']['data']['active'] && params['node']['data']['active'] == 1) {
						result = [
							'copy',
							'copyWithHeaders',
							'paste',
							'separator',
							'export',
							'separator',
							{
								name: "Deactivate",
								action: () => {
									self.deactivateNode['id'].push(params['node']['data']['id']);
									self.deactivateNode['active'] = 0;
									this.openConfirm('deactivate');
								},
							},
						];
					}
					else {
						result = [
							'copy',
							'copyWithHeaders',
							'paste',
							'separator',
							'export',
							'separator',
							{
								name: "Activate",
								action: () => {
									self.deactivateNode['id'].push(params['node']['data']['id']);
									self.deactivateNode['active'] = 1;
									this.openConfirm('activate');
								},
							},
						];
					}
				}

				return result;
			}
		},
		beforeMount() {
			// Table Data AXIOS Call
			this.populateTableData();

			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Reset pinned columns on mobile
			this.toggleColumnPinned(this.windowWidth);

			// Page Refresh Event
			this.$root.$on('refreshPage', () => {
				// Reset filters
				this.gridApi.setFilterModel(null);
				// Reset selection
				this.gridApi.deselectAll();
				// Reset sort
				this.gridApi.setSortModel([
					{
					colId: "created_at",
					sort: "desc"
					}
				]);
				// Table Data AXIOS Call
				this.populateTableData();
			});

			// Page Download Event
			this.$root.$on('downloadPage', () => {
				// AgGrid CSV Export
				this.gridApi.exportDataAsCsv()
			});

		},
		destroyed() {
			// Clear Listing Count
			this.$store.dispatch("clearListingCount");
			
			// Unbind Button Event Listeners
			this.$root.$off('refreshPage');
			this.$root.$off('downloadPage');
		}
	}

</script>